@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  margin: 0;
  font-family: "URW Geometric Ext", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.condensed {
  font-family: "URW Geometric Cond", sans-serif;
}

.geometric {
  font-family: "URW Geometric", sans-serif;
}

.recta-med {
  font-family: "Recta Medium", sans-serif;
  letter-spacing: 1.5px;
}

.recta-cond {
  font-family: "Recta Cond", sans-serif;
}

@font-face {
  font-family: "Recta Cond";
  src: url("../fonts/Recta-Condensed-webfont.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Recta Medium";
  src: url("../fonts/Recta-Medium-webfont.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'URW Geometric Cond';
  src: url('../fonts/URWGeometricCondRegular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'URW Geometric Ext';
  src: url('../fonts/URWGeometricExtRegular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'URW Geometric';
  src: url('../fonts/URWGeometric-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
